import HeaderCom from '../../../components/M-header.vue';

export default {
    name: 'audit',
    components: {
        HeaderCom
    },
    data() {
        return {
            total: 0, // 总条目数
            currentPage: 1, // 当前页
            limit: 10, // 每页显示数量
            loading: false,
            userInfo: {},
            formInline: {},
            tabColumns: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '登录名', key: 'userName', align: 'center'},
                {title: '用户名', slot: 'nickName', align: 'center'},
                {title: '手机号', slot: 'phonenumber', align: 'center'},
                {title: '账号生成时间', key: 'createTime', align: 'center'},
                {title: '操作', slot: 'action', width: 240, align: 'center'}
            ],
            tabData: [],
            editIndex: -1,  // 当前聚焦的输入框的行数
            editUsername: '', // 用户名输入框
            editRealName: '', // 真实姓名输入框
            editPhone: '', // 手机号输入框
            editCreateTime: '', // 账号生成时间输入框
            addUserModal: false,
            addUserForm: {
                userName: '',
                nickName: '',
                phonenumber: '',
            },
            addUserRule: {
                userName: [{required: true, message: '登录名为必填', trigger: 'change'}],
                nickName: [{required: true, message: '用户名为必填', trigger: 'change'}],
                phonenumber: [
                    {required: true, message: '手机号为必填', trigger: 'change'},
                    {pattern: /^1\d{10}$/, message: "手机号格式不对", trigger: "blur"}
                ]
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 表单清空
        resetForm(fileName) {
            this.$refs[fileName].resetFields();
        },
        onSearch() {
            this.currentPage = 1;
            this.getData();
        },
        // 页码改变的回调，返回改变后的页码(当前页)
        sizeChange(val) {
            this.currentPage = val;
            this.getData();
        },
        // 切换每页条数时的回调，返回切换后的每页条数(每页数)
        pageChange(val) {
            this.limit = val;
            this.getData();
        },
        getData() {
            this.loading = true;
            let params = {
                pageNum: this.currentPage,
                pageSize: this.limit,
                nickName: this.formInline.nickName,
                userType: this.formInline.userType
            }
            this.$manageHttp.userList(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.tabData = data.rows;
                    this.total = data.total;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 添加用户
        addUser() {
            this.addUserModal = true;
            this.resetForm('addUserForm');
        },
        // 添加用户---保存
        addSave(name) {
            var params = {
                userName: this.addUserForm.userName,
                nickName: this.addUserForm.nickName,
                phonenumber: this.addUserForm.phonenumber
            }
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$manageHttp.addUser(params).then(data => {
                        if (data.code == '200') {
                            this.$Modal.success({
                                title: '恭喜您，用户添加成功！',
                                content: '<p style="color:red">初始密码为123456</p>',
                                okText: '我知道了',
                                onOk: () => {
                                    this.addUserModal = false;
                                    this.getData();
                                }
                            });
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                } else {
                    return false;
                }
            })
        },
        // 修改
        updateBtn(row, index) {
            this.editRealName = row.nickName;
            this.editPhone = row.phonenumber;
            this.editIndex = index;
        },
        // 保存
        handleSave(row, index) {
            var params = {
                userId: row.userId,
                userName: row.userName,
                nickName: this.editRealName,
                phonenumber: this.editPhone
            }
            this.$manageHttp.updateUser(params).then(data => {
                if (data.code == '200') {
                    this.tabData[index].nickName = this.editRealName;
                    this.tabData[index].phonenumber = this.editPhone;
                    this.editIndex = -1;
                    this.$Message.success('修改成功!');
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 重置密码
        resetBtn(row) {
            var params = {
                userId: row.userId
            }
            this.$manageHttp.resetPwd(params).then(data => {
                if (data.code == '200') {
                    this.$Modal.success({
                        title: '密码重置成功！',
                        content: '<p style="color:red">已重置为初始密码: 123456</p>',
                        okText: '我知道了',
                        onOk: () => {

                        }
                    });
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 删除
        deleteBtn(row) {
            var params = {
                userId: row.userId
            }
            this.$manageHttp.deleteUserById(params).then(data => {
                if (data.code == '200') {
                    this.$Message.success('删除成功!');
                    setTimeout(() => {
                        this.getData();
                    }, 500)
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        }
    }
}